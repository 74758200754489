import { Stable, Sth, Std } from '../../components/StyledTable';
import { Card } from '../../components/Card'
import { Heading1, TextSpan } from '../../components/TextFormatting'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';

import wd from './Webinars.json';

function Webinar() {

  const { key } = useParams();
  const w = wd.find(e => e.key === key);
  console.log(w);

  return (
    <div>
          <br />
          <div className='background-cyan-500 mx-10 max-w-screen-lg lg:mx-auto'>
            <div className='border-solid border border-cyan-800 relative overflow-hidden w-full pt-[56.25%]'>
              <iframe title='Webinar Player' className='absolute top-0 left-0 bottom-0 right-0 w-full h-full' allowtransparency="true" id="ytplayer" type="text/html" src={'https://www.youtube.com/embed/' + w.key + '?autoplay=1&rel=0'} frameborder="0"></iframe>
            </div>
          </div>
          <Heading1>{w.nme}</Heading1>
          <TextSpan align="center">{w.spk + "(" + w.ins + ")"}</TextSpan>
          <Link to='/webinars'><p className='hover:text-blue-700 text-sm italic text-cyan-800 mt-4'>Voltar</p></Link>
   </div>
  );
}

function Webinars() {
    return (
      <div>
            <Heading1>Webinars</Heading1>
            <div className='hidden md:block'>
                <Stable>
                    <thead>
                        <tr>
                            <Sth>Palestrante</Sth>
                            <Sth>Institui&ccedil;&atilde;o</Sth>
                            <Sth>Data</Sth>
                            <Sth align="left">T&iacute;tulo da Apresenta&ccedil;&atilde;o</Sth>    
                        </tr>
                    </thead>
                    <tbody className='bg-white dark:bg-slate-800'>
                        {
                            wd.map((w, i) => { return(
                                <tr key={w.key}>
                                    <Std>{w.spk}</Std>
                                    <Std>{w.ins}</Std>
                                    <Std>{w.dte}</Std>
                                    <Std align="left">
                                      <Link
                                        to={'/webinars/' + w.key}
                                        className="hover:text-blue-700"
                                      >
                                        {w.nme}
                                      </Link>
                                    </Std>                                
                                </tr>
                            )})
                        }
                    </tbody>
                </Stable>
            </div>
            <div className='md:hidden'>
                <div className='container flex flex-wrap justify-center mx-auto'>
                    {wd.map((w, i) => { return(
                        <Card key={w.key}>
                          <Link to={'/webinars/' + w.key}>
                            <h5 className="hover:text-blue-700 mt-5 mb-1 text-md text-center font-bold tracking-tight text-gray-900">{w.nme}</h5>
                          </Link>
                          <TextSpan align="center">{w.spk + ' - ' + w.ins}</TextSpan>
                          <p className='text-center'>{w.dte}</p>
                        </Card>)})}
                </div>
            </div>
     </div>
    );
  }

export { Webinars, Webinar };