function Heading1(props) {
  return (
    props.align === 'left'?
      <h2 className='mt-7 mb-4 text-lg font-bold text-cyan-800 text-left'>{props.children}</h2>
    :props.align === 'right'?
      <h2 className='mt-7 mb-4 text-lg font-bold text-cyan-800 text-right'>{props.children}</h2>
    :<h2 className='mt-7 mb-4 text-lg font-bold text-cyan-800 text-center uppercase'>{props.children}</h2>
  )
}

function Heading2(props) {
  return (
    props.align === 'left'?
      <h3 className='mt-16 mb-4 text-md font-bold text-cyan-800 text-left'>{props.children}</h3>
    :props.align === 'right'?
      <h3 className='mt-16 mb-4 text-md font-bold text-cyan-800 text-right'>{props.children}</h3>
    :<h3 className='mt-16 mb-4 text-md font-bold text-cyan-800 text-center'>{props.children}</h3>
  )
}

function TextSpan(props) {
  return (
    props.align === 'left'?
      <p className='leading-relaxed text-left text-cyan-900'>{props.children}</p>
    :props.align === 'right'?
      <p className='leading-relaxed text-right text-cyan-900'>{props.children}</p>
    :props.align === 'center'?
      <p className='leading-relaxed text-center text-cyan-900'>{props.children}</p>
    :<p className='leading-relaxed text-justify text-cyan-900'>{props.children}</p>
  )
}

  export { Heading1, Heading2, TextSpan };