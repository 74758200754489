import { Stable, Sth, Std } from '../../components/StyledTable';
import { Card } from '../../components/Card'
import { Heading1, TextSpan } from '../../components/TextFormatting'

import cd from './Courses.json';

function Courses() {
    return (
        <div>
            <Heading1>Cursos e Especializa&ccedil;&otilde;es</Heading1>
            <div className='hidden md:block'>
                <Stable>
                    <thead>
                        <tr>
                            <Sth>T&iacute;tulo do Curso</Sth>
                            <Sth>Especializa&ccedil;&atilde;o</Sth>
                            <Sth>Departamento</Sth>
                            <Sth>N&iacute;vel</Sth>    
                        </tr>
                    </thead>
                    <tbody className='bg-white dark:bg-slate-800'>
                        {
                            cd.map((c, i) => { return(
                                <tr key={'c' + i}>
                                    <Std>{c.course}</Std>
                                    <Std>{c.spec}</Std>
                                    <Std>{c.dept}</Std>
                                    <Std>{c.level}</Std>                                
                                </tr>
                            )})
                        }
                    </tbody>
                </Stable>
            </div>
            <div className='md:hidden'>
                <div className='container flex flex-wrap justify-center mx-auto'>
                    {cd.map((c, i) => { return(
                        <Card key={'c' + i}>
                            <h5 className="mt-5 mb-1 text-md text-center font-bold tracking-tight text-cyan-800">{c.spec}</h5>
                            <TextSpan align="center">{c.course + ' - ' + c.dept}</TextSpan>
                            <p className='text-center italic'>{c.level}</p>
                        </Card>)})}
                </div>
            </div>
        </div>
    );
}

export { Courses };