import { TextSpan, Heading1 } from '../components/TextFormatting';

function Operation() {
    return (
        <div>
            <Heading1>Atuação em O&amp;G</Heading1>
            <TextSpan>O Programa de Engenharia Civil (PEC) da COPPE/UFRJ iniciou as atividades no setor de Petr&oacute;leo e G&aacute;s no ano de 1977, tendo portanto um papel pioneiro em atividades de pesquisa, desenvolvimento e forma&ccedil;&atilde;o de recursos humanos nesta &aacute;rea. Desde meados da d&eacute;cada de 70 (quando se confirmou a exist&ecirc;ncia das reservas da Bacia de Campos) at&eacute; o presente momento (quando se necessita de tecnologia para extrair petr&oacute;leo na camada Pr&eacute;-Sal a at&eacute; 3 mil metros de l&acirc;mina d&rsquo;&aacute;gua e 200 km ou mais de dist&acirc;ncia de terra firme), professores, alunos e pesquisadores do PEC vem executando atividades de pesquisa e ensino que ajudaram a colocar o Brasil na lideran&ccedil;a mundial da explora&ccedil;&atilde;o e produ&ccedil;&atilde;o em &aacute;guas profundas.</TextSpan>
            <br />
            <TextSpan>Esta atua&ccedil;&atilde;o vem tendo o reconhecimento da ANP: no primeiro edital do PRH (em Mar&ccedil;o de 1999), o PEC foi selecionado para organizar e incrementar seus cursos na &aacute;rea de Petr&oacute;leo e G&aacute;s no escopo do programa PRH-02, intitulado &ldquo;Forma&ccedil;&atilde;o de Profissionais de Engenharia Civil para o setor de Petr&oacute;leo e G&aacute;s&rdquo;, que teve continuidade com o atual PRH-9.1 Al&eacute;m disso, a ANP realizou o credenciamento de v&aacute;rios Laborat&oacute;rios do PEC para realizar atividades de pesquisa e desenvolvimento com recursos provenientes da Cl&aacute;usula de Investimento em P&amp;D constante dos contratos para explora&ccedil;&atilde;o, desenvolvimento e produ&ccedil;&atilde;o de petr&oacute;leo e g&aacute;s natural.</TextSpan>
        </div>
    );
  }

export { Operation }