import { Link } from "react-router-dom";
import { NavLink } from '../NavLink';
import prh9Logo from './assets/prh9.svg';

function Navbar() {
      return (
        <nav className="flex space-x-2 px-9 md:px-20 lg:px-32 bg-cyan-800 border-gray-200 py-2.5 rounded">
            <Link className='shrink-0' to='/'><img className="h-11" src={prh9Logo} alt="prh9" /></Link>
            <div className="container flex flex-wrap justify-end items-center mx-auto">
                
                <button data-collapse-toggle="mobile-menu" type="button" className="inline-flex items-center p-2 ml-auto text-sm text-cyan-50 rounded-lg md:hidden hover:bg-gray-100 hover:text-cyan-800 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mobile-menu-2" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                    <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
                    <ul className="flex flex-col mt-4 md:items-center md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <NavLink href="/">Apresentação</NavLink>
                        <NavLink href="/operation">Atuação</NavLink>
                        <NavLink href="/courses">Cursos e Especializa&ccedil;&otilde;es</NavLink>
                        <NavLink href="/classes">Disciplinas Associadas</NavLink>
                        <NavLink href="/team">Equipe</NavLink>
                        <NavLink href="/webinars">Webinars</NavLink>
                    </ul>
                </div>
            </div>
        </nav>
      );
    }

export { Navbar };