import { SButton } from '../../components/SButton';
import { Stable, Sth, Std } from '../../components/StyledTable';
import { Heading1, Heading2, TextSpan } from '../../components/TextFormatting'
import { HashLink } from 'react-router-hash-link';

import cd from './Classes.json';

function Classes() {
    return (
        <div>
            <Heading1>Disciplinas Associadas</Heading1>
            <div className='container flex flex-wrap justify-center mx-auto'>
                {cd.map(cr => { return(<HashLink key={'a' + cr.tag} to={'/classes#' + cr.tag}><SButton>{cr.level + ' - ' + cr.course }</SButton></HashLink>)})}
            </div>
            <br />
            <TextSpan align="center">Lista de disciplinas requeridas para bolsistas.</TextSpan>
            
            {
                cd.map(cr => { return(
                    <div key={'ld' + cr.tag}  id={cr.tag}>
                        <Heading2>{cr.level + ' - ' + cr.course }</Heading2>
                        <Stable>
                            <thead>
                                <tr>
                                    <Sth>C&oacute;digo</Sth>
                                    <Sth align="left">Nome</Sth>
                                </tr>
                            </thead>
                            <tbody className='bg-white dark:bg-slate-800'>
                                {
                                    cr.classes.map(c => { return(
                                        <tr key={cr.tag + c.code}>
                                            <Std>{c.code}</Std>
                                            <Std align="left">{c.name}</Std>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Stable>
                        <HashLink to='/classes#'><p className='hover:text-blue-700 text-sm italic text-cyan-800 mt-4'>Voltar</p></HashLink>
                    </div>
                )})
            }
        </div>
    );
}

export { Classes };