import { SButton } from '../../components/SButton';
import { Card } from '../../components/Card'
import { Heading1, Heading2, TextSpan } from '../../components/TextFormatting'
import { HashLink } from 'react-router-hash-link';

import td from './Teams.json';

function Team() {
    return (
        <div>
            <Heading1>Equipe</Heading1>
            <div className='container flex flex-wrap justify-center mx-auto'>
                {td.map((t, i) => { return(<HashLink key={'at' + i} to={'/team#a' + i}><SButton>{t.name}</SButton></HashLink>)})}
            </div>
            <br />
            {
                td.map((t, i) => { return(
                    <div key={'ld' + i}  id={'a' + i}>
                        <Heading2 align="center">{t.name}</Heading2>
                        <div className='container flex flex-wrap justify-center mx-auto'>
                            {t.people.map((p, j) => { return(
                                <Card key={'c' + i + j} img={require('./assets/' + p.img)} title={p.name}>
                                    {p.desc.map((d, k) => { return(
                                        <div key={'cd'+ i + j + k}>
                                            <h5 className="mt-5 mb-1 text-md text-center font-bold tracking-tight text-gray-900">{d.title}</h5>
                                            <TextSpan align="center">{d.text}</TextSpan>
                                            { !!p.lattes?<p className='mt-5'><a className='text-sky-600 font-semibold italic' href={p.lattes}>Currículo Lattes</a></p>:null}
                                        </div>
                                    )})}
                                </Card>)})}                                    
                        </div>
                        <HashLink to='/team#'><p className='hover:text-blue-700 text-sm italic text-cyan-800 mt-4'>Voltar</p></HashLink>
                    </div>
                )})
            }
        </div>
    );
}

export { Team };

