import './App.css';

import logoPrh from './assets/prh_anp.png';
import logoAnp from './assets/anp.png';
import logoFinep from './assets/finep.png';
import logoUfrj from './assets/ufrj.png';

import { Routes, Route } from "react-router-dom";
import { Navbar } from './components/Navbar/Navbar';

import { Home } from './routes/Home/Home';
import { Operation } from './routes/Operation';
import { Courses } from './routes/Courses/Courses';
import { Classes } from './routes/Classes/Classes';
import { Team } from './routes/Team/Team';
import { Webinars, Webinar } from './routes/Webinars/Webinars';

function App() {
  return (

    <div className="App">
      <Navbar/>
      <div className='mx-10  md:mx-32'>
        <h2 className="mt-6 self-center text-lg text-cyan-800 font-bold">PRH-9.1 Formação de Profissionais de Engenharia Civil para o setor de Petróleo e Gás</h2>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="operation" element={<Operation />} />
          <Route path="courses" element={<Courses />} />
          <Route path="classes" element={<Classes />} />
          <Route path="team" element={<Team />} />
          <Route exact path="webinars" element={<Webinars />} />
          <Route path="webinars/:key" element={<Webinar />} />
        </Routes>
        <div className="mt-16 container flex flex-wrap justify-center mx-auto">
          <a href='https://www.gov.br/anp/pt-br/assuntos/pesquisa-desenvolvimento-e-inovacao/prh-anp-programa-de-formacao-de-recursos-humanos-1'><img className="h-11 mr-4" src={logoPrh} alt="PRH" /></a>
          <a href='https://www.gov.br/anp/pt-br/'><img className="h-11 mx-4" src={logoAnp} alt="ANP" /></a>
          <a href='http://www.finep.gov.br/'><img className="h-11 mx-4" src={logoFinep} alt="Finep" /></a>
          <a href='https://ufrj.br/'><img className="h-11" src={logoUfrj} alt="UFRJ" /></a>
        </div>
        <br />
      </div>
    </div>
  );
}

export default App;
