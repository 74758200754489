import { Link } from "react-router-dom";

function NavLink(props) {
    return (
        <li>
            <Link
                to={props.href}
                className="block py-2 pr-4 pl-3 text-cyan-50 border-b border-gray-100 hover:bg-gray-50 hover:text-cyan-800 md:hover:bg-transparent md:border-0 md:hover:text-amber-400 md:p-0"
            >
                {props.children}
            </Link>
        </li>
    )
  }

  export { NavLink };