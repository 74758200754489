
function Card(props) {
    return (
        <div className="mx-2 mb-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md">
            {!!props.img?<img className='rounded-full border-sky-700 border-4 w-40 mx-auto mt-5' src={props.img} alt='' />:null}
            <div className="p-5">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-cyan-800">{props.title}</h5>
                <div className="mb-3 font-normal text-cyan-900">{props.children}</div>
            </div>
        </div>
    )
}

function SqCard(props) {
    return (
        <div className="mx-2 mb-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
            <div className='h-64 overflow-hidden'>
                <img className="object-cover rounded-t-lg" src={props.img} alt='' />
            </div>
            <div className="p-5">
                <h5 className="mb-2 text-md font-bold tracking-tight text-cyan-800">{props.title}</h5>
                <div className="mb-3 font-normal text-cyan-900">{props.children}</div>
            </div>
        </div>
    )
}

export { Card, SqCard };