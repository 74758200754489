import { SqCard } from '../../components/Card'
import { Heading1, TextSpan } from '../../components/TextFormatting';

function Home() {
    return (
        <div>
            <Heading1>Apresentação</Heading1>
            <TextSpan>No âmbito dos Programas de Recursos Humanos (PRH) da Agência Nacional de Petróleo, Gás Natural e Bio-combustíveis (ANP), o PRH-9.1, intitulado “Formação de Profissionais de Engenharia Civil para o setor de Petróleo e Gás”, visa contribuir com a geração e disseminação de conhecimento científico e tecnologias aplicadas em disciplinas relacionadas a Engenharia Civil e Engenharia de Petróleo. O PRH-9 é formado por pesquisadores do Programa de Engenharia Civil do Instituto Alberto Luiz Coimbra de Pós-Graduação e Pesquisa em Engenharia (COPPE) e da Escola Politécnica da Universidade Federal do Rio de Janeiro (UFRJ) que possuem atuação acadêmica e desenvolvem pesquisas para o Setor de Petróleo, Gás Natural e Bio-combustíveis, particularmente nas áreas de Exploração e Produção, Estruturas Oceânicas, Sistemas de Produção Offshore, Engenharia Submarina, entre outros tópicos.</TextSpan>
            <br />
            <br />
            <div className='container flex flex-wrap justify-center mx-auto'>
                <SqCard img={require('./assets/ct.png')} title='Centro de Tecnologia (CT) da UFRJ' />
                <SqCard img={require('./assets/fpso.png')} title='Típico Sistema de Produção Offshore baseado em plataforma FPSO' />
            </div>
        </div>
    );
  }

export { Home };