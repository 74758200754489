function Stable(props) {
    return (
        <div className="mx-10">
        <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
            <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]" style={{backgroundPosition: '10px 10px'}}></div>
            <div className="relative rounded-xl overflow-auto">
                <div className="shadow-sm overflow-hidden my-8">
                    <table className='border-collapse table-auto w-full text-sm'>
                        {props.children}
                    </table>
                    </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                </div>
            </div>
    )
  }

function Sth(props) {
    return (
        props.align === 'left'?
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-cyan-800 text-left'>{props.children}</th>
        :props.align === 'right'?
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-cyan-800 text-right'>{props.children}</th>
        :<th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-cyan-800 text-center'>{props.children}</th>
    )
  }

function Std(props) {
    return (
        props.align === 'left'?
        <td className='border-b border-slate-100 p-4 pl-8 text-slate-500 text-left'>{props.children}</td>
    :props.align === 'right'?
        <td className='border-b border-slate-100 p-4 pl-8 text-slate-500 text-right'>{props.children}</td>
    :<td className='border-b border-slate-100 p-4 pl-8 text-slate-500 text-center'>{props.children}</td>
    )
}

  export { Stable, Sth, Std };